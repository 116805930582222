import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const LatestUpdate = ({ latestData }) => {
  const [medalsData, setMedalsData] = useState([]);
  const navigate = useNavigate();

  const getMadels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}medal`
      );
      setMedalsData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMadels();
  }, []);

  return (
    <Box my={14}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
        <Box
          background={
            "linear-gradient(11deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(254,55,0,1) 100%)"
          }
          backgroundColor={"rgb(0,0,0)"}
          p={5}
        >
          <Heading color={"white"}>Medals</Heading>
          <VStack spacing={3} color={""} alignItems={"start"} mt={5}>
            <Box
              bgColor={"transparent"}
              color={"white"}
              borderRadius={"md"}
              w={"100%"}
            >
              <Flex justify={"space-between"} alignItems={"center"} p={2}>
                <Flex gap={{ base: 3, sm: 6 }}>
                  <Text>#</Text>
                  <Text>Teams/NOCs</Text>
                </Flex>
                <Flex gap={{ base: 3, sm: 6 }}>
                  <SimpleGrid columns={4} spacing={8}>
                  <Text>G</Text>
                  <Text>S</Text>
                  <Text>B</Text>
                  <Text>T</Text>
                  </SimpleGrid>
                </Flex>
              </Flex>
            </Box>
            {medalsData?.slice(0, 7).map((item, index) => (
              <Flex
                justify={"space-between"}
                alignItems={"center"}
                w={"100%"}
                h={{ base: "80px", sm: "auto" }}
                bgColor={"white"}
                p={2}
                key={index}
                borderRadius={"md"}
              >
                <Flex gap={{ base: 3, sm: 6 }}>
                  <Text>{item.rank}</Text>
                  <Box
                    display={"flex"}
                    gap={{ base: 0, md: 2 }}
                    flexWrap={{ base: "wrap", md: "nowrap" }}
                  >
                    <Box w={"40px"} h={"30px"} fontWeight={500}>
                      {" "}
                      <Image
                        src={item.countryFlag}
                        w={"100%"}
                        h={"100%"}
                        alt={item.countryName}
                      />{" "}
                    </Box>
                    <Text> {item.countryName} </Text>
                  </Box>
                </Flex>
                <Flex gap={{ base: 3, sm: 6 }}>
                  <SimpleGrid columns={4} spacing={6}>

                  <Text >
                    {item.totalGoldMedals < 10
                      ? `0${item.totalGoldMedals}`
                      : item.totalGoldMedals}
                  </Text>
                  <Text >
                    {item.totalSilverMedals < 10
                      ? `0${item.totalSilverMedals}`
                      : item.totalSilverMedals}
                  </Text>
                  <Text >
                    {item.totalBronzeMedals < 10
                      ? `0${item.totalBronzeMedals}`
                      : item.totalBronzeMedals}
                  </Text>
                  <Text >
                    {item.totalMedalCount < 10
                      ? `0${item.totalMedalCount}`
                      : item.totalMedalCount}
                  </Text>
                  </SimpleGrid>
                </Flex>
              </Flex>
            ))}
            <Box display={"flex"} justifyContent={"end"} width={"100%"}>
              <a href="/medals">
                <Button
                  display={"flex"}
                  gap={2}
                  justifyContent={"end"}
                  // onClick={() => navigate("/medals")}
                >
                  View All <HiOutlineArrowNarrowRight />
                </Button>
              </a>
            </Box>
          </VStack>
        </Box>
        <Box>
          <Flex gap={2} alignItems={"center"}>
            <Text backgroundColor={"red"} borderRadius={"50%"} p={1}></Text>
            <Heading fontSize={"20px"} textTransform={"capitalize"}>
              LATEST UPDATES
            </Heading>
          </Flex>
          <Divider border={"1px solid black"} my={2} />
          {latestData?.slice(0, 7).map((item, index) => (
            <Box
              key={index}
              cursor={"pointer"}
              // onClick={() => navigate(`/blog-details?id=${item.id}`)}
            >
              <a href={`/blog-details?id=${item.id}`}>
                <VStack align={"start"} spacing={4}>
                  <Flex alignItems={"center"} gap={6} mt={4}>
                    <Text fontSize={"17px"} fontWeight={500}>
                      {index + 1}.
                    </Text>
                    <Box>
                      <Text fontSize={"14px"} mb={1} color={"gray"}>
                        {" "}
                        {new Date(item.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </Text>
                      <Text fontSize={"18px"} color={"#000"}>
                        {item.title}
                      </Text>
                    </Box>
                  </Flex>
                </VStack>
              </a>
            </Box>
          ))}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default LatestUpdate;
