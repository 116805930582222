import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Divider,
} from '@chakra-ui/react';

const Disclaimer = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={5} align="start">
        <Heading as="h1" size="xl">
          Disclaimer
        </Heading>

        <Text>
          We appreciate your visit to our website. We created this platform to
          provide information about news, entertainment, sports, and politics,
          as well as to offer insights and resources in these areas.
        </Text>

        <Divider />

        <Text>
          To ensure transparency and clarity, we have incorporated the following
          disclaimers:
        </Text>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            Informational Purposes Only
          </Heading>
          <Text>
            The sole purpose of this website is to provide information. The
            content of our website should not be construed as professional
            guidance or advice.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            No Professional Guidance
          </Heading>
          <Text>
            The information presented on this site is not intended to replace
            professional advice. If you have specific questions or require
            guidance in financial, legal, or other professional matters, we
            strongly suggest that you consult a qualified professional advisor.
            This website is not a substitute for personalized advice and
            services.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            No Professional Relationship
          </Heading>
          <Text>
            Accessing this website does not establish a professional
            relationship or agreement between you and our team. To become a
            client, a formal written agreement is required.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            External Links
          </Heading>
          <Text>
            Our website may contain links to other websites. We provide these
            links as a convenience and for informational purposes only. We do
            not have control over the content or accuracy of these external
            websites and cannot assume responsibility for their content.
          </Text>
        </Box>

        <Box pl={4} borderLeft="4px" borderColor="#FE3700">
          <Heading as="h3" size="md" mb={2}>
            Case Studies and Testimonials
          </Heading>
          <Text>
            Any case studies or client testimonials displayed on our website are
            for illustrative purposes only. These examples do not guarantee
            similar outcomes for all clients. Individual results may vary based
            on specific circumstances.
          </Text>
        </Box>

        <Text>
          Thank you for your understanding and for visiting our website. If you
          have any questions or need further clarification, please feel free to
          contact us.
        </Text>
      </VStack>
    </Container>
  );
};

export default Disclaimer;