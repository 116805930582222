import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";

const ReadMore = ({ entertainment }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 600);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const { title, id } = useParams();
  const navigate = useNavigate();

  const allEntertainment = entertainment.find(
    (item) => item.title === title.split("-").join(" ")
  );

  const subEntertainment = allEntertainment?.subData;
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(Number(id) - 1 || 0);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    // navigate(
    //   `/${allEntertainment?.title.split(" ").join("-")}/${selected + 1}`
    // );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const offset = currentPage * itemsPerPage;
  const currentItems = subEntertainment.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(subEntertainment.length / itemsPerPage);

  return (
    <Box>
      <Container maxW={"container.xl"}>
        <Box
          boxShadow={
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }
          p={{ base: 5, md: 10 }}
          // mt={"120px"}
          // mb={"70px"}
        >
          <Box>
            <Flex
              justify={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Heading
                textAlign={"center"}
                fontSize={{ base: "20px", md: "35px" }}
                width={{ lg: "70%", md: "75%", sm: "60%", base: "100%" }}
              >
                {allEntertainment.title}
              </Heading>
              <Box
                color={"#686868"}
                mt={8}
                fontSize={{ base: "14px", md: "18px" }}
                dangerouslySetInnerHTML={{
                  __html: allEntertainment?.description,
                }}
              ></Box>
            </Flex>
          </Box>
          {currentItems?.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}  mt={8}>
              {currentItems?.map((item, index) => (
                <Box bg="white" boxShadow="md" key={index}>
                  <Image src={item?.image} alt={item?.title} w={"100%"} h={"250px"} objectFit={'cover'} />
                  <Box p={4}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "18px", md: "23px" }}
                      display="inline-block"
                      cursor="pointer"
                      noOfLines={1}
                      _hover={{ color: "#FE3700" }}
                    >
                      {item?.title}
                    </Text>
                    <Box
                      mt={2}
                      fontWeight={400}
                      display="-webkit-box"
                      overflow="hidden"
                      sx={{
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: "3",
                        textOverflow: "ellipsis",
                        lineHeight: "1.2em",
                        maxHeight: "4.2em",
                      }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={3}
                    >
                      <Box
                        bgColor={"#000"}
                        fontSize={"16px"}
                        color={"white"}
                        fontWeight={600}
                        mt={"10px"}
                        padding={"2px 10px"}
                      >
                        <Link
                        // to={`/${item.title}`.split(" ").join("-")}
                        >
                          More
                        </Link>
                      </Box>
                      <Text fontSize="14px" color="#B7B7B7" fontWeight={400}>
                        {item.yearAgo}
                      </Text>
                    </Flex>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
           ''
          )}

          {currentItems?.length > 0 ? (
            <Flex justifyContent="center" mt={4}>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={isSmallScreen ? 1 : 2}
                pageRangeDisplayed={isSmallScreen ? 1 : 3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            </Flex>
          ) : (
            ""
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ReadMore;
