import { Container, Heading } from "@chakra-ui/react";
import React from "react";

const Contact = () => {
  return (
    <div>
      <Container maxW={"container.xl"}>
        <Heading mb={10} borderBottom={"2px solid #FE3700"} w={"10.5%"}>
          Contact
        </Heading>
      </Container>
    </div>
  );
};

export default Contact;
