import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [trendingData, setTrendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const shuffledBlogs = shuffleArray([...allBlogs]);

  const sectionSize = Math.ceil(shuffledBlogs.length / 4);

  const section1 = shuffledBlogs
    .slice(0, sectionSize)
    .map((item) => item?.Blogs);
  const section2 = shuffledBlogs
    .slice(sectionSize, sectionSize * 2)
    .map((item) => item?.Blogs);
  const section3 = shuffledBlogs
    .slice(sectionSize * 2)
    .map((item) => item?.Blogs);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };

  const getAllblogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/all-category-blog`
      );
      const allBlogs = response.data.data;
      setAllBlogs(allBlogs);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getLatestData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/latest`
      );
      const latestData = response.data.data;
      setLatestData(latestData);
    } catch (error) {
      console.log(error);
    }
  };

  const getTrendingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/trending`
      );
      const allData = response.data.data;
      setTrendingData(allData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllblogs();
    getLatestData();
    getTrendingData();
  }, []);

  const recentBlogs = allBlogs
    ?.map((item) => {
      const blogs = item.Blogs;
      if (blogs && blogs.length > 0) {
        return blogs.slice(-2);
      }
      return [];
    })
    .flat();

  const filteredLatestData = latestData.filter(
    (latestItem) =>
      !recentBlogs.some((recentBlog) => recentBlog?.title === latestItem?.title)
  );

  return (
    <Box>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"90vh"}
          w={"100%"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#FE3700"
            size="xl"
          />
        </Box>
      ) : (
        <>
          <Box marginTop={"60px"}>
            <Container maxW={"container.xl"}>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
                {recentBlogs.map((item, index) => (
                  <Box
                    borderRadius="md"
                    overflow="hidden"
                    boxShadow="md"
                    bg="#f3f3f3"
                    p={4}
                    key={index}
                    cursor={"pointer"}
                    // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                  >
                    <a href={`/blog-details?id=${item.id}`}>
                      <Box w={"100%"} h={"200px"} position={"relative"}>
                        {item.logo.length > 0 && (
                          <Image
                            src={item.logo[0]}
                            w={"100%"}
                            h={"100%"}
                            alt={item?.title}
                          />
                        )}
                        <Box position={"absolute"} top={0} p={2}>
                          <Badge
                            bgColor={"#FE3700"}
                            fontWeight={400}
                            color={"white"}
                            p={"5px 12px"}
                            borderRadius={"full"}
                            mb={2}
                          >
                            Recent
                          </Badge>
                        </Box>
                      </Box>
                      <Text
                        fontSize="13px"
                        mt={2}
                        color="#979797"
                        fontWeight={400}
                      >
                        {new Date(item.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </Text>
                      <Text
                        color={"black"}
                        fontSize={"16px"}
                        fontWeight={700}
                        noOfLines={2}
                      >
                        {item.title}
                      </Text>
                      <Text
                        color={"black"}
                        mt={2}
                        fontSize={"15px"}
                        fontWeight={400}
                        noOfLines={3}
                      >
                        {item.sub_description}
                      </Text>
                      {item?.sourceBy && (
                        <Text
                          fontSize="13px"
                          mt={3}
                          color="#000"
                          textAlign={"right"}
                          fontWeight={400}
                        >
                          <b>Source By :</b> {item?.sourceBy}
                        </Text>
                      )}
                    </a>
                  </Box>
                ))}
              </SimpleGrid>
            </Container>
          </Box>

          {/* latest news */}
          <Container maxW={"container.xl"} mt={10}>
            <Heading
              textTransform={"uppercase"}
              bgColor={"#091324"}
              padding={3}
              borderLeft={"10px solid #FE3700"}
              fontWeight={700}
              color={"white"}
              fontSize={{ base: "24px", md: "30px" }}
            >
              Latest news
            </Heading>
            <Box mt={8}>
              <Slider {...settings}>
                {filteredLatestData?.length > 0 &&
                  shuffleArray([...filteredLatestData])?.map((item, index) => (
                    <Box
                      p={2}
                      key={index}
                      cursor={"pointer"}
                      // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                    >
                      <a href={`/blog-details?id=${item.id}`}>
                        <Box
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          p={{ base: 2, md: 4 }}
                          height={"500px"}
                        >
                          {item?.logo?.length > 0 && (
                            <Image
                              src={item?.logo[0]}
                              alt={item?.title}
                              w={"100%"}
                              h={"250px"}
                            />
                          )}

                          <Box mt={4}>
                            <Stack direction="row" align="center" mb={2}>
                              <Badge
                                bgColor="#FE3700"
                                variant="solid"
                                borderRadius={"full"}
                                p={"5px 10px"}
                              >
                                Live
                              </Badge>
                              <Text fontSize="sm" color="#FE3700">
                                {new Date(item.created_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </Text>
                            </Stack>

                            <Text
                              mt={1}
                              fontWeight="semibold"
                              as="h4"
                              lineHeight="tight"
                              noOfLines={2}
                            >
                              {item.title}
                            </Text>

                            <Text
                              mt={2}
                              color="gray.500"
                              fontSize="sm"
                              noOfLines={4}
                            >
                              {item.sub_description}
                            </Text>
                            {item?.sourceBy && (
                              <Text
                                fontSize="13px"
                                mt={3}
                                color="#000"
                                textAlign={"right"}
                                fontWeight={400}
                              >
                                <b>Source By :</b> {item?.sourceBy}
                              </Text>
                            )}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                  ))}
              </Slider>
            </Box>
          </Container>
          <Box display={"flex"} justifyContent={"center"} my={5}>
            <Box width={"728px"} height={"90px"} bgColor={"#f3f3f3"}></Box>
          </Box>
          {/* more from us */}
          <Container maxW={"container.xl"} mt={10}>
            <Heading
              textTransform={"uppercase"}
              fontWeight={700}
              fontSize={{ base: "24px", md: "30px" }}
            >
              more from us
            </Heading>
            <Box my={10}>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={6}
              >
                {section1?.length > 0 &&
                  section1[0]?.slice(0, 9).map((item, index) => (
                    <a href={`/blog-details?id=${item.id}`} key={index}>
                      <Box
                        borderRadius="md"
                        display={"flex"}
                        justifyContent={{ base: "center", sm: "start" }}
                        alignItems={{ base: "center", sm: "start" }}
                        flexDirection={{ base: "column", sm: "row" }}
                        gap={3}
                        // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                        cursor={"pointer"}
                      >
                        {item && (
                          <>
                            {item?.logo?.length > 0 && (
                              <Image
                                src={item?.logo[0]}
                                alt={item?.title}
                                boxSize={{ base: "100%", sm: "150px" }}
                                mb={4}
                                objectFit="cover"
                              />
                            )}
                            <Box textAlign={{ base: "center", sm: "start" }}>
                              <Text fontWeight="bold" mb={2} noOfLines={2}>
                                {item?.title}
                              </Text>
                              <Text noOfLines={4}>{item?.sub_description}</Text>
                            </Box>
                          </>
                        )}
                      </Box>
                    </a>
                  ))}
              </Grid>
            </Box>
          </Container>
          {/* top news */}
          <Container maxW={"container.xl"} my={10}>
            <Heading
              textTransform={"uppercase"}
              bgColor={"#091324"}
              padding={3}
              borderLeft={"10px solid #FE3700"}
              fontWeight={700}
              color={"white"}
              fontSize={{ base: "24px", md: "30px" }}
              mb={10}
            >
              Top news
            </Heading>
            <VStack spacing={5}>
              {section2?.length > 0 &&
                section2[0]?.slice(0, 8).map((item, index) => {
                  const cardIndex = index + 1;
                  return (
                    <>
                      <Flex
                        gap={6}
                        key={index}
                        borderColor={"inherit"}
                        cursor={"pointer"}
                        flexDir={{ base: "column", md: "row" }}
                        w={"100%"}
                      >
                        {item?.logo?.length > 0 && (
                          <Box w={{ base: "100%", md: "400px" }} h={"220px"}>
                            <Image
                              src={item?.logo[0]}
                              alt={item?.title}
                              w={"100%"}
                              h={"100%"}
                              objectFit={"cover"}
                            />
                          </Box>
                        )}
                        <Stack flex="1" minW="0" alignItems={"start"}>
                          <Flex alignItems={"center"} gap={3} mt={2}>
                            {" "}
                            <Badge
                              bgColor="#FE3700"
                              variant="solid"
                              borderRadius={"full"}
                              p={"5px 10px"}
                            >
                              Upcoming
                            </Badge>
                            <Text fontSize="sm" color="#FE3700">
                              {new Date(item?.created_at).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </Text>
                          </Flex>
                          <Text
                            fontWeight={500}
                            fontSize={{ base: "20px", md: "24px" }}
                            noOfLines={2}
                            cursor={"pointer"}
                            _hover={{
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              navigate(`/blog-details?id=${item?.id}`)
                            }
                          >
                            <a href={`/blog-details?id=${item?.id}`}>
                              {item?.title}
                            </a>
                          </Text>
                          <Text fontSize={{ base: "16px" }} noOfLines={3}>
                            {item?.sub_description}
                          </Text>
                          {item?.sourceBy && (
                            <Text
                              fontSize="13px"
                              color="#000"
                              textAlign={"right"}
                              fontWeight={400}
                            >
                              <b>Source By :</b> {item?.sourceBy}
                            </Text>
                          )}
                        </Stack>
                      </Flex>
                      {cardIndex % 3 === 0 && cardIndex !== item.length && (
                        <div key={`ad-${cardIndex}`} py={6}>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            my={5}
                          >
                            <Box
                              width={"728px"}
                              height={"90px"}
                              bgColor={"#f3f3f3"}
                            ></Box>
                          </Box>
                        </div>
                      )}
                    </>
                  );
                })}
            </VStack>
          </Container>
          {/* Recent post */}
          <Container maxW={"container.xl"}>
            <Heading
              textTransform={"uppercase"}
              fontWeight={700}
              fontSize={{ base: "24px", md: "30px" }}
            >
              Recent post
            </Heading>
            <Box>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing={5}
                my={10}
              >
                {section3[0]?.slice(0, 8).map((item, index) => {
                  const cardIndex = index + 1;
                  return (
                    <>
                      <Box key={index}>
                        {item?.logo?.length > 0 && (
                          <Box
                            bg="#D9D9D9"
                            height={{ md: "260px", base: "220px" }}
                          >
                            <Image
                              src={item?.logo[0]}
                              alt={item?.title}
                              w="100%"
                              h={"100%"}
                            />
                          </Box>
                        )}
                        <Box
                          bg="#091324"
                          p={3}
                          height={"100px"}
                          // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                        >
                          <a href={`/blog-details?id=${item?.id}`}>
                            <Text
                              color="white"
                              textAlign="center"
                              fontSize={"20px"}
                              fontWeight={600}
                              _hover={{
                                textDecoration: "underline",
                              }}
                              cursor={"pointer"}
                              noOfLines={2}
                            >
                              {item?.title}
                            </Text>
                          </a>
                        </Box>
                      </Box>
                      {/* {cardIndex === 4 && cardIndex !== item.length && (
                          <div key={`ad-${cardIndex}`} py={6}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <Box
                                width={"300px"}
                                height={"300px"}
                                bgColor={"#f3f3f3"}
                              ></Box>
                            </Box>
                          </div>
                        )} */}
                    </>
                  );
                })}
              </SimpleGrid>
              <Box
                bgColor={"#f3f3f3"}
                padding={"30px 20px"}
                mt={14}
                color={"black"}
              >
                <Heading textTransform={"uppercase"} fontSize={"24px"}>
                  Website Disclaimer{" "}
                </Heading>
                <Divider borderColor={"black"} my={2} />
                <Text mt={4}>
                  The information provided by <b>8bitinfosys</b> ("We","us" or
                  "our") on{" "}
                  <b style={{ color: "#FE3700", cursor: "pointer" }}>
                    https://mediamingle.live/
                  </b>{" "}
                  is for general informational purposes only. All information on
                  the Site is provided in good faith, however, we make no
                  representation or warranty of any kind, express or implied,
                  regarding the accuracy, adequacy, validity, reliability,
                  availability, or completeness of any information on the Site.
                </Text>
                <Text my={3}>
                  UNDER NO CIRCUMSTANCE SHALL WE BE LIABLE FOR ANY DIRECT,
                  INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES
                  RESULTING FROM YOUR USE OF OR RELIANCE ON ANY INFORMATION
                  PROVIDED ON THIS SITE. WHETHER RELATED TO NEWS, ENTERTAINMENT,
                  SPORTS, OR POLITICS, THE CONTENT MAY NOT ALWAYS REFLECT THE
                  MOST RECENT DEVELOPMENTS OR ACCURATE PERSPECTIVES. YOU ARE
                  SOLELY RESPONSIBLE FOR VERIFYING THE INFORMATION YOU USE OR
                  ACT UPON, AND ANY DECISIONS MADE BASED ON CONTENT FROM THIS
                  SITE ARE AT YOUR OWN RISK.
                </Text>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </Box>
  );
};

export default Home;
