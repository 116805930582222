import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const SportBlogDetails = () => {
  const [blog, setBlog] = useState({});
  const [singleBlog, setSingleBlog] = useState(null);
  const [trendingData, setTrendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const querySlug = new URLSearchParams(location.search).get("id");

  const getOneBlog = async () => {
    setLoading(false);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/${querySlug}`
      );
      setBlog(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  };

  const getTrendingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/trending?categoryName=`
      );
      setTrendingData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchSlug = querySlug;
    setSingleBlog(fetchSlug);
    if (!querySlug) {
      // window.location.reload('/blogs')
    }
  }, [querySlug]);

  useEffect(() => {
    getOneBlog();
    getTrendingData();
  }, [singleBlog]);

  const filteredSports = trendingData.filter(
    (item) => item.id !== Number(singleBlog)
  );

  return (
    <Container maxW={"7xl"}>
      {loading ? (
        <Stack mt={10}>
          <Heading textAlign={"center"} size={{ base: "lg", xl: "xl" }}>
            {blog.title}
          </Heading>
          <Box width={"100%"} height={{ md: "600px", base: "100%" }}>
            {blog?.logo?.length > 0 && (
              <Image
                src={blog?.logo[0]}
                mt={10}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
                alt={blog.title}
              />
            )}
          </Box>
          <Flex
            py={10}
            gap={10}
            alignItems={"flex-start"}
            flexDir={{ base: "column", xl: "row" }}
          >
            <Box width={{ xl: "70%" }}>
              <Box display={"flex"} justifyContent={"center"} mt={10}>
                <Box width={"300px"} height={"300px"} bgColor={"#f3f3f3"}></Box>
              </Box>
              <Box my={5} textAlign={"right"}>
                <Text>
                  <b>Source By :</b> {blog.sourceBy}
                </Text>
              </Box>
              <Text
                dangerouslySetInnerHTML={{ __html: blog.description }}
                fontSize={{ base: "16px", md: "18px" }}
              />
              {blog?.logo[1] && (
                <Box mt={5} display={"flex"} justifyContent={"center"}>
                  <Box
                    width={"300px"}
                    height={"300px"}
                    bgColor={"#f3f3f3"}
                  ></Box>
                </Box>
              )}
              {blog?.logo[1] && (
                <Box width={"100%"} height={{ base: "100%", md: "500px" }}>
                  <Image
                    src={blog?.logo[1]}
                    mt={10}
                    w={"100%"}
                    h={"100%"}
                    objectFit={"cover"}
                    alt={blog.title}
                  />
                </Box>
              )}
            </Box>
            <Flex
              width={{ xl: "30%" }}
              justifyContent={"center"}
              flexDir={"column"}
              gap={6}
              mt={10}
            >
              <Heading
                textAlign={{ xl: "center" }}
                textTransform={"uppercase"}
                size={"md"}
              >
                Related content
              </Heading>
              <Box display={"flex"} justifyContent={"center"} mt={5}>
                <Box width={"300px"} height={"300px"} bgColor={"#f3f3f3"}></Box>
              </Box>
              <Flex flexDir={"column"} gap={4}>
                {filteredSports.slice(0, 6)?.map((item, index) => (
                  <a href={`/blog-details?id=${item.id}`} key={index}>
                    <Flex
                      gap={4}
                      border={"1px"}
                      p={2}
                      borderColor={"inherit"}
                      cursor={"pointer"}
                      alignItems={"center"}
                      // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                    >
                      {item?.logo?.length > 0 && (
                        <Box w={"120px"} h={"70px"}>
                          <Image
                            src={item?.logo[0]}
                            alt={item.title}
                            w={"100%"}
                            h={"100%"}
                            objectFit={"cover"}
                          />
                        </Box>
                      )}
                      <Stack flex="1" minW="0">
                        <Text fontWeight={500} fontSize={"md"} noOfLines={2}>
                          {item.title}
                        </Text>
                        {item?.gameName !== "" && (
                          <Text
                            border={"1px"}
                            py={0.5}
                            px={2}
                            borderRadius={"md"}
                            borderColor={"inherit"}
                            width={"fit-content"}
                          >
                            {item?.gameName}
                          </Text>
                        )}
                      </Stack>
                    </Flex>
                  </a>
                ))}
              </Flex>
              <Box display={"flex"} justifyContent={"center"} my={5}>
                <Box width={"300px"} height={"300px"} bgColor={"#f3f3f3"}></Box>
              </Box>
            </Flex>
          </Flex>
        </Stack>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"90vh"}
          w={"100%"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#FE3700"
            size="xl"
          />
        </Box>
      )}
    </Container>
  );
};

export default SportBlogDetails;
