import {
  Box,
  Container,
  Divider,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);

  const getCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}category`
      );
      setCategory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);
  return (
    <Box
      bgColor={"#000"}
      borderTop={"6px solid #FE3700"}
      p={{ base: "20px", md: "50px" }}
      mt={10}
    >
      <Container maxW={"container.xl"}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Image
              src={
                "https://actressfashion.s3.eu-north-1.amazonaws.com/logo+fevicon02-04.png"
              }
              alt="Mediamingle.live"
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <VStack
              spacing={4}
              alignItems={{ lg: "start", base: "center" }}
              mt={3}
            >
              <Text color={"#fff"} fontWeight={500} fontSize={"20px"}>
                Category
              </Text>
              {category?.map((item, index) => (
                <Text
                  key={index}
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"16px"}
                  // onClick={() =>
                  //   navigate(`/category?name=${item.name.toLowerCase()}`)
                  // }
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <a href={`/category?name=${item.name.toLowerCase()}`}>
                    {item.name}
                  </a>
                </Text>
              ))}
            </VStack>
          </Box>
          <Box>
            <VStack
              spacing={4}
              alignItems={{ lg: "start", base: "center" }}
              mt={3}
            >
              <Text color={"#fff"} fontWeight={500} fontSize={"20px"}>
                Our Policies and Terms
              </Text>
              <Text
                color={"#dee2e6"}
                fontWeight={400}
                cursor={"pointer"}
                fontSize={"16px"}
              >
                Cookie Policy
              </Text>
              <Text
                color={"#dee2e6"}
                fontWeight={400}
                cursor={"pointer"}
                fontSize={"16px"}
              >
                Cookie Settings
              </Text>
              <Text
                color={"#dee2e6"}
                fontWeight={400}
                cursor={"pointer"}
                fontSize={"16px"}
              >
                Privacy Policy
              </Text>
              <Text
                color={"#dee2e6"}
                fontWeight={400}
                cursor={"pointer"}
                fontSize={"16px"}
              >
                Terms of Service
              </Text>
              <Text
                color={"#dee2e6"}
                fontWeight={400}
                cursor={"pointer"}
                fontSize={"16px"}
                // onClick={() => navigate('/disclaimer')}
              >
                <a href="/disclaimer">Disclaimer</a>
              </Text>
            </VStack>
          </Box>
          <Box>
            <VStack
              spacing={3}
              alignItems={{ lg: "start", base: "center" }}
              justifyContent={{ lg: "start", md: "center" }}
              mt={3}
            >
              <Text color={"#fff"} fontWeight={500} fontSize={"20px"}>
                Contact Us
              </Text>

              <Box display={"flex"} gap={2}>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"25px"}
                >
                  <MdLocationPin />
                </Text>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"16px"}
                >
                  209, Sunrise Complex, Savjibhai Korat Bridge, Lajamani chowk,
                  Mota Varachha, Surat, Gujarat 394101, India
                </Text>
              </Box>
              <Box display={"flex"} gap={2}>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"25px"}
                >
                  <MdCall />
                </Text>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"16px"}
                >
                  +91 90818 03333
                </Text>
              </Box>
              <Box display={"flex"} gap={3}>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"25px"}
                >
                  <MdEmail />
                </Text>
                <Text
                  color={"#dee2e6"}
                  fontWeight={400}
                  cursor={"pointer"}
                  fontSize={"16px"}
                >
                  8bitinfosys@gmail.com
                </Text>
              </Box>
            </VStack>
          </Box>
        </SimpleGrid>
        <Divider my={5} />
        <Text textAlign={"center"} color={"#dee2e6"}>
          Copyright &#169; {new Date().getFullYear()} Mediamingle.live
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
