import { Container, Heading } from "@chakra-ui/react";
import React from "react";

const HealthyEating = () => {
  return (
    <div>
      <Container maxW={"container.xl"}>
        <Heading mb={10} borderBottom={"2px solid #FE3700"} w={"20%"}>
          Healthy Eating
        </Heading>
      </Container>
    </div>
  );
};

export default HealthyEating;
