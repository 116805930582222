import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Image,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";
import TeamProfile from "../teamProfile";
import gold from "../../Assets/gold.png";
import silver from "../../Assets/silver.png";
import bronze from "../../Assets/bronze.png";
import total from "../../Assets/total.png";
import { FaSquareLetterboxd } from "react-icons/fa6";

const Medals = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [teamMember, setTeamMember] = useState({});
  const [gameName, setGameName] = useState(null);
  const [medalsData, setMedalsData] = useState([]);
  const [medalsCountryData, setMedalsCountryData] = useState([]);
  const [medalsIDData, setMedalsIDData] = useState([]);
  const [medalsID, setMedalsID] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [rank, setRank] = useState(null);
  const [loading, setLoading] = useState(false)
  const [teamLoading, setTeamLoading] = useState(false)

  const getMadels = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}medal`
      );
      setMedalsData(response.data.data);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
  };

  const getMadelsCountry = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}medal/winners/${gameName}/${medalsID}`
      );
      setMedalsCountryData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMadelsByID = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}medal/${medalsID}`
      );
      setMedalsIDData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamProfile = async () => {
    setTeamLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}medal/winner/${teamId}`
      );
      setTeamMember(response.data.data);
    } catch (error) {
      console.log(error);
    }finally{
      setTeamLoading(false)
    }
  };

  useEffect(() => {
    if (teamId) {
      getTeamProfile();
    }
  }, [teamId]);

  useEffect(() => {
    getMadels();
  }, []);

  useEffect(() => {
    if (gameName) {
      getMadelsCountry();
    }
  }, [gameName, medalsID]);

  useEffect(() => {
    if (medalsID) {
      getMadelsByID();
    }
  }, [medalsID]);

  return (
    <Box>
      {!loading ? (
      <Container maxW={"container.xl"} my={14}>
        <Box
          bgColor={"transparent"}
          color={"#000"}
          borderRadius={"md"}
          w={"100%"}
        >
          <Flex justify={"space-between"} alignItems={"center"} px={4}>
            <Flex gap={{ base: 3, md: 6 }}>
              <Text fontSize={"18px"} fontWeight={500}>
                #
              </Text>
              <Text fontSize={"18px"} fontWeight={500}>
                NOCs
              </Text>
            </Flex>
            <Flex gap={{ base: 0, md: 1 }}>
              <Text fontSize={"18px"} fontWeight={500} px={1.5}>
                <Image
                  src={gold}
                  alt="gold"
                  w={{ base: 6, md: 7 }}
                  h={{ base: 6, md: 7 }}
                />
              </Text>
              <Text fontSize={"18px"} fontWeight={500} px={1.5}>
                <Image
                  src={silver}
                  alt="silver"
                  w={{ base: 6, md: 7 }}
                  h={{ base: 6, md: 7 }}
                />
              </Text>
              <Text fontSize={"18px"} fontWeight={500} px={1.5}>
                <Image
                  src={bronze}
                  alt="bronze"
                  w={{ base: 6, md: 7 }}
                  h={{ base: 6, md: 7 }}
                />
              </Text>
              <Text fontSize={"18px"} fontWeight={500} px={1.5}>
                <Image
                  src={total}
                  alt="total"
                  w={{ base: 6, md: 7 }}
                  h={{ base: 6, md: 7 }}
                />
              </Text>
              <Text fontSize={"18px"} fontWeight={500} color={"#fff"} px={1.5}>
                g
              </Text>
            </Flex>
          </Flex>

          <Accordion allowToggle borderTopWidth={0} borderColor={"transparent"}>
            {medalsData?.map((item, index) => (
              <AccordionItem key={index}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        _hover={{
                          bgColor: "#fff",
                        }}
                        p={0}
                        onClick={() => {
                          setMedalsID(item.id);
                          // setMedalsIDData([]);
                        }}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          <Flex
                            justify={"space-between"}
                            alignItems={"center"}
                            w={"100%"}
                            h={{ base: "100px", md: "auto" }}
                            bgColor={"white"}
                            color={"#000"}
                            p={3}
                            boxShadow={"lg"}
                            border={"1px solid gray"}
                            borderRadius={"md"}
                            mt={3}
                          >
                            <Flex gap={{ base: 3, md: 6 }} w={"100%"}>
                              <Text>{item.rank}</Text>
                              <Box
                                gap={{ base: 0, md: 2 }}
                                flexWrap={{ base: "wrap", md: "nowrap" }}
                                display={"flex"}
                              >
                                <Box w={"40px"} h={"30px"} fontWeight={500}>
                                  <Image
                                    src={item.countryFlag}
                                    alt={item.countryName}
                                    w={"100%"}
                                    h={"100%"}
                                  />
                                </Box>
                                <Text> {item.countryName} </Text>
                              </Box>
                            </Flex>
                            <Flex gap={{ base: 4, md: 6 }} align={"center"}>
                              <Text>
                                {item.totalGoldMedals < 10
                                  ? `0${item.totalGoldMedals}`
                                  : item.totalGoldMedals}
                              </Text>
                              <Text>
                                {item.totalSilverMedals < 10
                                  ? `0${item.totalSilverMedals}`
                                  : item.totalSilverMedals}
                              </Text>
                              <Text>
                                {item.totalBronzeMedals < 10
                                  ? `0${item.totalBronzeMedals}`
                                  : item.totalBronzeMedals}
                              </Text>
                              <Text>
                                {item.totalMedalCount < 10
                                  ? `0${item.totalMedalCount}`
                                  : item.totalMedalCount}
                              </Text>
                              <Text>
                                {isExpanded ? (
                                  <FiMinusSquare size={25} />
                                ) : (
                                  <FiPlusSquare size={25} />
                                )}
                              </Text>
                            </Flex>
                          </Flex>
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      p={0}
                      border={"1px solid #E2E8F0"}
                      borderRadius={"md"}
                    >
                      <Accordion allowToggle borderColor={"transparent"}>
                        {medalsID === item.id &&
                          medalsIDData?.map((data, index) => (
                            <AccordionItem key={index}>
                              {({ isExpanded }) => (
                                <>
                                  <h2>
                                    <AccordionButton
                                      _hover={{
                                        bgColor: "#fff",
                                      }}
                                      p={0}
                                      onClick={() => {
                                        setGameName(data.gameName);
                                        // setMedalsCountryData([]);
                                      }}
                                    >
                                      <Box as="span" flex="1" textAlign="left">
                                        <Flex
                                          justify={"space-between"}
                                          alignItems={"center"}
                                          w={"100%"}
                                          bgColor={"white"}
                                          color={"#000"}
                                          p={3}
                                          boxShadow={"lg"}
                                          borderBottom={"1px solid #E2E8F0"}
                                          borderRadius={"md"}
                                          mt={0}
                                        >
                                          <Flex gap={{ base: 2, md: 8 }}>
                                            <Text>{data.rank}</Text>
                                            <Box
                                              display={"flex"}
                                              gap={2}
                                              fontWeight={500}
                                            >
                                              {data.gameName}
                                            </Box>
                                          </Flex>
                                          <Flex
                                            gap={{ base: 4, md: 6 }}
                                            align={"center"}
                                          >
                                            <Text>
                                              {data.totalGoldMedals < 10
                                                ? `0${data.totalGoldMedals}`
                                                : data.totalGoldMedals}
                                            </Text>
                                            <Text>
                                              {data.totalSilverMedals < 10
                                                ? `0${data.totalSilverMedals}`
                                                : data.totalSilverMedals}
                                            </Text>
                                            <Text>
                                              {data.totalBronzeMedals < 10
                                                ? `0${data.totalBronzeMedals}`
                                                : data.totalBronzeMedals}
                                            </Text>
                                            <Text>
                                              {data.totalMedalCount < 10
                                                ? `0${data.totalMedalCount}`
                                                : data.totalMedalCount}
                                            </Text>
                                            <Text>
                                              {isExpanded ? (
                                                <FiMinusSquare size={25} />
                                              ) : (
                                                <FiPlusSquare size={25} />
                                              )}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Box>
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    p={0}
                                    border={"1px solid #E2E8F0"}
                                    borderRadius={"md"}
                                  >
                                    {medalsCountryData.length > 0 &&
                                      medalsCountryData?.map(
                                        (country, index) => (
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            key={index}
                                          >
                                            {(country.team === true ||
                                              (country.teamName &&
                                                country.teamName.trim() !==
                                                  "") ||
                                              (country.winnerName &&
                                                country.winnerName.trim() !==
                                                  "")) && (
                                              <Flex
                                                justify={"space-between"}
                                                alignItems={"center"}
                                                w={"100%"}
                                                bgColor={"red.50"}
                                                color={"#000"}
                                                p={3}
                                                boxShadow={"lg"}
                                                borderBottom={
                                                  "1px solid #FE3700"
                                                }
                                                mt={0}
                                              >
                                                <Flex gap={{ base: 2, md: 8 }}>
                                                  <Text>{country.rank}</Text>
                                                  <Box
                                                    display={"flex"}
                                                    gap={2}
                                                    fontWeight={500}
                                                  >
                                                    {country.team === true
                                                      ? country.teamName
                                                      : country.winnerName}
                                                  </Box>
                                                </Flex>
                                                <Flex
                                                  gap={{
                                                    base: 4,
                                                    md:
                                                      country.team !== true
                                                        ? 6
                                                        : 12,
                                                  }}
                                                  align={"center"}
                                                >
                                                  <Text>
                                                    {country.medal ===
                                                      "Silver" && (
                                                      <Image
                                                        src={silver}
                                                        alt="silver"
                                                        w={{ base: 6, md: 7 }}
                                                        h={{ base: 6, md: 7 }}
                                                      />
                                                    )}
                                                    {country.medal ===
                                                      "Gold" && (
                                                      <Image
                                                        src={gold}
                                                        alt="gold"
                                                        w={{ base: 6, md: 7 }}
                                                        h={{ base: 6, md: 7 }}
                                                      />
                                                    )}
                                                    {country.medal ===
                                                      "Bronze" && (
                                                      <Image
                                                        src={bronze}
                                                        alt="bronze"
                                                        w={{ base: 6, md: 7 }}
                                                        h={{ base: 6, md: 7 }}
                                                      />
                                                    )}
                                                  </Text>
                                                  <Box>
                                                    {country.team !== true && (
                                                      <Text
                                                        onClick={() => {
                                                          onOpen();
                                                          setTeamId(country.id);
                                                          setRank(item);
                                                        }}
                                                        cursor={"pointer"}
                                                      >
                                                        <FaSquareLetterboxd
                                                          size={25}
                                                        />
                                                      </Text>
                                                    )}
                                                  </Box>
                                                </Flex>
                                              </Flex>
                                            )}
                                          </Box>
                                        )
                                      )}
                                  </AccordionPanel>
                                </>
                              )}
                            </AccordionItem>
                          ))}
                      </Accordion>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Container>) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"90vh"}
          w={"100%"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#FE3700"
            size="xl"
          />
        </Box>
      )}
      <TeamProfile
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        teamId={teamId}
        rank={rank}
        teamMember={teamMember}
        setTeamMember={setTeamMember}
        loading={teamLoading}
      />
    </Box>
  );
};

export default Medals;
