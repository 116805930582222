import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LatestUpdate from "./latestUpdate";

const Sport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [trendingData, setTrendingData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [gameName, setGameName] = useState("");
  const [allGames, setAllGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 12;

  const [singleBlog, setSingleBlog] = useState(null);
  const querySlug = new URLSearchParams(location.search).get("name");
  const [currentIndex, setCurrentIndex] = useState(ITEMS_PER_PAGE);

  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const filterAndShuffleData = () => {
    const filteredData = latestData.filter((item) =>
      gameName ? item.gameName === gameName : true
    );
    return filteredData;
  };

  const [displayedData, setDisplayedData] = useState([]);

  const getTrendingData = async () => {
    setLoading(false);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/trending?categoryName=${querySlug}`
      );
      setTrendingData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  };

  const getLatestData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blog/latest`,
        {
          params: {
            categoryName: querySlug,
            gameName: gameName,
          },
        }
      );
      const latestData = response.data.data;
      setAllGames([...new Set(latestData.map((item) => item.gameName))]);
      setLatestData(latestData);
      setDisplayedData(filterAndShuffleData().slice(0, ITEMS_PER_PAGE));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchSlug = querySlug;
    setSingleBlog(fetchSlug);
    if (!querySlug) {
      // window.location.reload('/blogs')
    }
  }, [querySlug]);

  useEffect(() => {
    getTrendingData();
    getLatestData();
  }, [singleBlog]);

  useEffect(() => {
    if (latestData.length > 0) {
      // Always filter and shuffle data when latestData or gameName changes
      const filteredAndShuffledData = filterAndShuffleData();
      setDisplayedData(filteredAndShuffledData.slice(0, currentIndex));
    }
  }, [latestData, gameName, currentIndex]);

  const loadMore = () => {
    const newIndex = currentIndex + ITEMS_PER_PAGE;
    const filteredAndShuffledData = filterAndShuffleData();
    setDisplayedData(filteredAndShuffledData.slice(0, newIndex));
    setCurrentIndex(newIndex);
  };

  return (
    <div>
      {loading ? (
        <Container maxW={"container.xl"}>
          <div
            style={{
              display: "inline-block",
              borderBottom: "2px solid #FE3700",
              marginBottom: "10px",
              marginTop: "40px",
            }}
          >
            <Heading>Top Stories</Heading>
          </div>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} mt={10} spacing={4}>
            {trendingData?.slice(0, 2).map((item, index) => (
              <Box bg="white" boxShadow="md" key={index}>
                <Box w={"100%"} h={"320px"}>
                  {item?.logo?.length > 0 && (
                    <Image
                      src={item?.logo[0]}
                      w={"100%"}
                      h={"100%"}
                      alt={item?.title}
                      objectFit={"cover"}
                    />
                  )}
                </Box>
                <Box p={4}>
                  {item?.sourceBy && (
                    <Text fontSize="13px" mb={3} color="#000" fontWeight={400}>
                      <b>Source By :</b> {item?.sourceBy}
                    </Text>
                  )}
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "18px", md: "23px" }}
                    display="-webkit-box"
                    overflow="hidden"
                    sx={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "2",
                      textOverflow: "ellipsis",
                      lineHeight: "1.2em",
                      maxHeight: "2.4em",
                    }}
                    cursor={"pointer"}
                    onClick={() => navigate(`/blog-details?id=${item.id}`)}
                    _hover={{
                      color: "#FE3700",
                    }}
                  >
                    <a href={`/blog-details?id=${item.id}`}>{item?.title}</a>
                  </Text>

                  <Box
                    mt={2}
                    fontWeight={400}
                    display="-webkit-box"
                    overflow="hidden"
                    sx={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "3",
                      textOverflow: "ellipsis",
                      lineHeight: "1.2em",
                      maxHeight: "4.2em",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.sub_description }}
                  />
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={3}
                  >
                    <Box
                      bgColor={"#000"}
                      fontSize={"16px"}
                      color={"white"}
                      fontWeight={600}
                      mt={"10px"}
                      padding={"2px 10px"}
                    >
                      <Link to={`/blog-details?id=${item.id}`}>More</Link>
                    </Box>
                    <Text fontSize="14.5px" color="#979797" fontWeight={400}>
                      {new Date(item.created_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} my={10} spacing={4}>
            {trendingData?.slice(2, 5).map((item, index) => (
              <Box bg="white" boxShadow="md" key={index}>
                <Image
                  src={item.logo[0]}
                  w={"100%"}
                  h={"250px"}
                  alt={item?.title}
                  objectFit={"cover"}
                />
                <Box p={4}>
                  {item?.sourceBy && (
                    <Text fontSize="13px" mb={3} color="#000" fontWeight={400}>
                      <b>Source By :</b> {item?.sourceBy}
                    </Text>
                  )}
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "18px", md: "23px" }}
                    display="-webkit-box"
                    overflow="hidden"
                    sx={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "2",
                      textOverflow: "ellipsis",
                      lineHeight: "1.2em",
                      maxHeight: "2.4em",
                    }}
                    cursor={"pointer"}
                    onClick={() => navigate(`/blog-details?id=${item.id}`)}
                    _hover={{
                      color: "#FE3700",
                    }}
                  >
                    <a href={`/blog-details?id=${item.id}`}>{item?.title}</a>
                  </Text>

                  <Box
                    mt={2}
                    fontWeight={400}
                    display="-webkit-box"
                    overflow="hidden"
                    sx={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "3",
                      textOverflow: "ellipsis",
                      lineHeight: "1.2em",
                      maxHeight: "4.2em",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.sub_description }}
                  />
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={3}
                  >
                    <Box
                      bgColor={"#000"}
                      fontSize={"16px"}
                      color={"white"}
                      fontWeight={600}
                      mt={"10px"}
                      padding={"2px 10px"}
                    >
                      <a href={`/blog-details?id=${item.id}`}>More</a>
                    </Box>
                    <Text fontSize="14.5px" color="#979797" fontWeight={400}>
                      {new Date(item.created_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <Box width={"300px"} height={"300px"} bgColor={"#f3f3f3"}></Box>
          </Box>
          {location.pathname.concat(location.search) ===
            "/category?name=sports" && <LatestUpdate latestData={latestData} />}
          {location.pathname.concat(location.search) ===
            "/category?name=sports" && (
            <Box display={"flex"} justifyContent={"center"} my={5}>
              <Box width={"728px"} height={"90px"} bgColor={"#f3f3f3"}></Box>
            </Box>
          )}

          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <div
              style={{
                display: "inline-block",
                borderBottom: "2px solid #FE3700",
                marginBottom: "10px",
              }}
            >
              <Heading>Latest news</Heading>
            </div>
            {location.pathname.concat(location.search) ===
              "/category?name=sports" && (
              <Select
                placeholder="Select Game"
                size="lg"
                w={"30%"}
                onChange={(e) => setGameName(e.target.value)}
              >
                {allGames?.map(
                  (item, index) =>
                    item !== "" && (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                )}
              </Select>
            )}
          </Flex>

          {/* <SimpleGrid
            mt={10}
            mb={5}
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={5}
          >
            {shuffleArray(displayedData).map((item, index) => {
              const cardIndex = index + 1;
              return (
                <>
                  <Box
                    cursor={"pointer"}
                    // onClick={() => navigate(`/blog-details?id=${item.id}`)}
                    key={index}
                  >
                    <a href={`/blog-details?id=${item.id}`}>
                      <Box
                        width={"100%"}
                        height={"250px"}
                        position={"relative"}
                      >
                        {item?.logo?.length > 0 && (
                          <Image
                            src={item?.logo[0]}
                            w={"100%"}
                            h={"100%"}
                            alt={item?.title}
                            objectFit={"cover"}
                          />
                        )}
                        {item.gameName !== "" && (
                          <Box
                            position={"absolute"}
                            bottom={2}
                            left={2}
                            bgColor={"#fff"}
                            padding={"5px 10px"}
                          >
                            <Text fontSize={"12px"} fontWeight={500}>
                              {item.gameName}
                            </Text>
                          </Box>
                        )}
                      </Box>
                      <Heading
                        mt={2}
                        fontSize={"20px"}
                        _hover={{
                          color: "#FE3700",
                        }}
                        onClick={() => navigate(`/blog-details?id=${item.id}`)}
                      >
                        {item.title}
                      </Heading>
                    </a>
                  </Box>
                  {cardIndex % 9 === 0 &&
                    cardIndex !== displayedData.length && (
                      <Box py={6} display={"flex"} justifyContent={"center"}>
                        <Box
                          width={"728px"}
                          height={"90px"}
                          bgColor={"#f3f3f3"}
                        />
                      </Box>
                    )}
                </>
              );
            })}
          </SimpleGrid> */}
          <>
            {Array.from(
              { length: Math.ceil(displayedData.length / 9) },
              (_, i) => {
                const startIndex = i * 9;
                const chunk = displayedData.slice(startIndex, startIndex + 9);

                return (
                  <React.Fragment key={i}>
                    <SimpleGrid
                      mt={10}
                      mb={5}
                      columns={{ base: 1, md: 2, lg: 3 }}
                      spacing={5}
                    >
                      {chunk.map((item, index) => (
                        <Box cursor={"pointer"} key={startIndex + index}>
                          <a href={`/blog-details?id=${item.id}`}>
                            <Box
                              width={"100%"}
                              height={"250px"}
                              position={"relative"}
                            >
                              {item?.logo?.length > 0 && (
                                <Image
                                  src={item?.logo[0]}
                                  w={"100%"}
                                  h={"100%"}
                                  alt={item?.title}
                                  objectFit={"cover"}
                                />
                              )}
                              {item.gameName !== "" && (
                                <Box
                                  position={"absolute"}
                                  bottom={2}
                                  left={2}
                                  bgColor={"#fff"}
                                  padding={"5px 10px"}
                                >
                                  <Text fontSize={"12px"} fontWeight={500}>
                                    {item.gameName}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                            <Heading
                              mt={2}
                              fontSize={"20px"}
                              _hover={{
                                color: "#FE3700",
                              }}
                            >
                              {item.title}
                            </Heading>
                          </a>
                        </Box>
                      ))}
                    </SimpleGrid>

                    {/* Ad after every 9 items */}
                    {startIndex + chunk.length < displayedData.length && (
                      <Box
                        key={`ad-${startIndex}`}
                        py={6}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Box
                          width={"728px"}
                          height={"90px"}
                          bgColor={"#f3f3f3"}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                );
              }
            )}
          </>
          <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
            {currentIndex < filterAndShuffleData().length && (
              <Button
                my={10}
                onClick={loadMore}
                color={"white"}
                bgColor={"#000"}
                _hover={{
                  bgColor: "#000",
                  color: "white",
                }}
                mt={10}
                padding={"10px 30px"}
              >
                Load More...
              </Button>
            )}
          </Box>
        </Container>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"90vh"}
          w={"100%"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#FE3700"
            size="xl"
          />
        </Box>
      )}
    </div>
  );
};

export default Sport;
