import { Container, Heading } from "@chakra-ui/react";
import React from "react";

const Education = () => {
  return (
    <div>
      <Container maxW={"container.xl"}>
        <Heading mb={10} borderBottom={"2px solid #FE3700"} w={"14%"}>
          Education
        </Heading>
      </Container>
    </div>
  );
};

export default Education;
