import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";

const Entertainment = ({ entertainment }) => {
  const navigate = useNavigate();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 600);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const { pageNo } = useParams();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(pageNo) - 1 || 0);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    // navigate(`/entertainment/page/${selected + 1}`);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const offset = currentPage * itemsPerPage;
  const currentItems = entertainment.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(entertainment.length / itemsPerPage);
  
  return (
    <Container maxW={"container.xl"} mb={10}>
      <Heading mb={10} borderBottom={"2px solid #FE3700"} w={"20%"}>
        Entertainment
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        {currentItems?.map((item, index) => (
          <Box
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            p={4}
            key={index}
            borderRadius={"md"}
          >
            <Heading fontSize={"25px"}>{item.title}</Heading>
            <Text mt={1} color={"gray"} fontWeight={500}>
              {item.date}
            </Text>
            <Box mt={4} borderLeft={"3px solid #FE3700"} pl={3}>
              <Text
                fontSize={"18px"}
                dangerouslySetInnerHTML={{ __html: item.description1 ? item.description1 : item.description }}
                noOfLines={3}
              />
              <Button
                mt={4}
                onClick={() => navigate(`/${item.title.split(" ").join("-")}`)}
                _hover={{
                  textDecoration: "underline",
                }}
              >
                Read More
              </Button>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
      {currentItems?.length > 0 ? (
          <Flex justifyContent="center" mt={4}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={isSmallScreen ? 1 : 2}
              pageRangeDisplayed={isSmallScreen ? 1 : 3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={currentPage}
            />
          </Flex>
        ) : (
          ""
        )}
    </Container>
  );
};

export default Entertainment;
