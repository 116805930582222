import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import gold from "../../Assets/gold.png";
import silver from "../../Assets/silver.png";
import bronze from "../../Assets/bronze.png";

const TeamProfile = ({
  isOpen,
  onOpen,
  onClose,
  teamId,
  rank,
  teamMember,
  setTeamMember,
  loading,
}) => {
  
  return (
    <Box>
      <Modal onClose={onClose} size={"3xl"} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent sx={{ margin: { base: "10px", md: "1px" } }}>
          <ModalHeader>Team Profile</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setTeamMember({});
            }}
          />
          <ModalBody pb={5}>
            {!loading ? (
              <Box>
                <Flex gap={9} flexDirection={{ base: "column", md: "row" }}>
                  <Box
                    display={"flex"}
                    justifyContent={{ base: "center", md: "start" }}
                    alignItems={{ base: "center" }}
                  >
                    <Box w={"200px"} h={"100%"}>
                      <Image
                        src={teamMember?.image}
                        alt=""
                        w={"100%"}
                        h={"100%"}
                        objectFit={"cover"}
                      />
                    </Box>
                  </Box>
                  <VStack spacing={2} alignItems={"start"}>
                    <Box
                      display={"flex"}
                      justifyContent={{ base: "center", md: "start" }}
                      alignItems={{ base: "center", md : 'start' }}
                      flexDirection={"column"}
                      w={'100%'}
                    >
                      <Text fontWeight={700} fontSize={"18px"}>
                        {teamMember?.winnerName}
                      </Text>
                      <Flex gap={2} mt={2}>
                        <Box w={"40px"} h={"25px"}>
                          <Image
                            src={rank?.countryFlag}
                            alt=""
                            w={"100%"}
                            h={"100%"}
                          />
                        </Box>
                        <Text fontWeight={500} fontSize={"16px"}>
                          {" "}
                          {rank?.countryName}
                        </Text>
                      </Flex>
                    </Box>
                    <Divider borderColor={"black"} mt={3} />
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2} mt={3}>
                      <Box width={"100%"}>
                        <Flex gap={2}>
                          <Text fontWeight={600} fontSize={"16px"}>
                            Rank :
                          </Text>
                          <Text fontWeight={400} fontSize={"16px"}>
                            {rank?.rank}
                          </Text>
                        </Flex>
                        <Flex gap={2} mt={2}>
                          <Text fontWeight={600} fontSize={"16px"}>
                            Game :
                          </Text>
                          <Text fontWeight={400} fontSize={"16px"}>
                            {teamMember?.gameName}
                          </Text>
                        </Flex>
                        <Flex gap={2} mt={2}>
                          <Text fontWeight={600} fontSize={"16px"}>
                            Date of Birth :
                          </Text>
                          <Text fontWeight={400} fontSize={"16px"}>
                            {new Date(teamMember.birthDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </Text>
                        </Flex>
                      </Box>
                      <Box>
                        <Flex gap={2}>
                          <Text fontWeight={600} fontSize={"16px"}>
                            Medal :
                          </Text>
                          <Text fontWeight={400} fontSize={"16px"}>
                            <Text>
                              {teamMember?.medal === "Silver" && (
                                <Image
                                  src={silver}
                                  alt="silver"
                                  w={{ base: 6, md: 7 }}
                                  h={{ base: 6, md: 7 }}
                                />
                              )}
                              {teamMember?.medal === "Gold" && (
                                <Image
                                  src={gold}
                                  alt="gold"
                                  w={{ base: 6, md: 7 }}
                                  h={{ base: 6, md: 7 }}
                                />
                              )}
                              {teamMember?.medal === "Bronze" && (
                                <Image
                                  src={bronze}
                                  alt="bronze"
                                  w={{ base: 6, md: 7 }}
                                  h={{ base: 6, md: 7 }}
                                />
                              )}
                            </Text>
                          </Text>
                        </Flex>
                        <Flex gap={2} mt={2}>
                          <Text fontWeight={600} fontSize={"16px"}>
                            Gender :
                          </Text>
                          <Text fontWeight={400} fontSize={"16px"}>
                            {teamMember?.gender}
                          </Text>
                        </Flex>
                      </Box>
                    </SimpleGrid>
                  </VStack>
                </Flex>
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                w={"100%"}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="#FE3700"
                  size="xl"
                />
              </Box>
            )}
          </ModalBody>
          {/* <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setTeamMember({});
              }}
            >
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TeamProfile;
