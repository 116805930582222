import { Container, Heading } from "@chakra-ui/react";
import React from "react";

const MedicalProblems = () => {
  return (
    <div>
      <Container maxW={"container.xl"}>
        <Heading mb={10} borderBottom={"2px solid #FE3700"} w={"25%"}>
          Medical Problems
        </Heading>
      </Container>
    </div>
  );
};

export default MedicalProblems;
